import React from 'react';

const Buy_Sell = () => {
  return (
    <div>
      <h1 className='text-2xl text-white mt-10'>Comming Soon Buy & Sell</h1>
    </div>
  )
}

export default Buy_Sell;